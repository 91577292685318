import { render, staticRenderFns } from "./AllDashboards.vue?vue&type=template&id=afa8c9f0&scoped=true"
import script from "./AllDashboards.vue?vue&type=script&lang=js"
export * from "./AllDashboards.vue?vue&type=script&lang=js"
import style0 from "./AllDashboards.vue?vue&type=style&index=0&id=afa8c9f0&prod&lang=scss&scoped=true"
import style1 from "./AllDashboards.vue?vue&type=style&index=1&id=afa8c9f0&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afa8c9f0",
  null
  
)

export default component.exports