<template>
    <div class="container">
        <!-- Close Button -->
        <img src="../icons/crossButton.svg" @click="handleClose"
            style="height: 18px;cursor: pointer;position: fixed;right: 10px;top: 8px;" />

        <!-- Left Section: Thumbnails (Only show after Metabase has loaded) -->
        <div v-if="thumbnails.length" class="left-section">
            <div style="display: flex;align-items: center;justify-content: space-between;" class="">
                <h5 style="margin-bottom: 0px !important;margin-top: 0px !important;">Account Trends & Charts</h5>
                <span style="color: blue;font-size: 12px;">(Beta Feature)</span>
            </div>
            
            <div v-for="(thumbnail, index) in thumbnails" :key="index" class="thumbnail-container">
                <img v-tooltip="'Click to copy'" :src="thumbnail" class="thumbnail"
                    @click="copyToClipboard(thumbnail)" />
            </div>
        </div>

        <!-- Right Section: Metabase Iframe or Google Slides -->
        <div class="right-section">
            <div v-if="loading" class="loading-overlay">
                <img src="../assets/img/preloader5.gif" style="height: 12vh;" />
                <p>Loading and capturing data...</p>
            </div>
            <iframe id="iframeappeq" ref="metabaseIframe" v-if="currentIframeUrl"
                style="border: none; height: 100vh; width: 100%;" :src="currentIframeUrl"></iframe>
        </div>

        <!-- Footer with PDF Button -->
        <button v-if="!loadingBtn" @click="downloadPdf" :disabled="!thumbnails.length" class="pdf-button">
            Attach as PDF
        </button>
        <img src="../icons/loadingripple.svg" style="height: 18px;" v-else />
    </div>
</template>

<script>
// import html2canvas from "html2canvas"; // Uncomment if you are using it
import LocalUtils from "../components/Utils/LocalUtils";

export default {
    data() {
        return {
            dashboardUrl: "", // Metabase dashboard URL
            returnBlob: null,
            loadingBtn: false,
            loading: false,
            googleSlidesUrl: "https://docs.google.com/presentation/d/1Aj9NGZBDdCx-6wJR6qb7G3xsTA6qNkCan_AySLMfiyM/edit#slide=id.p7", // Google Slides URL
            currentIframeUrl: "", // Currently displayed iframe URL
            thumbnails: [], // Captured thumbnails
        };
    },
    props: {
        dashboardId: String ,
        params:{}
    },
    mounted() {
        this.loadDashboard();
        if(this.params && this.params['assist'] && this.params['assist']['attachmentLink']){
            this.googleSlidesUrl=this.params['assist']['attachmentLink']
        }       
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        async loadDashboard() {
            this.loading = true;
            this.dashboardUrl = await LocalUtils.openDash({ metabaseNumber: this.dashboardId.toString() });
            this.currentIframeUrl = this.dashboardUrl;
            this.captureQuestionsAndCreateThumbnails();
        },

        async captureQuestionsAndCreateThumbnails() {
            try {
                console.log("Iframe loaded");
                    setTimeout(async () => {

                        const iframeDocument = this.$refs.metabaseIframe.contentDocument || this.$refs.metabaseIframe.contentWindow.document;

                        const questions = iframeDocument.querySelectorAll('[data-testid="dashcard-container"]');
                        const images = await Promise.all(
                            Array.from(questions).map((question) => this.captureQuestionAsImage(question))
                        );

                        this.thumbnails = images;
                        this.loading = false;

                        // After capturing, switch to Google Slides
                        this.currentIframeUrl = this.googleSlidesUrl;
                    }, 6000);


            } catch (error) {
                console.error("Error capturing questions:", error);
            }
        },

        async captureQuestionAsImage(questionElement) {
            try {
                const canvas = await html2canvas(questionElement);
                return canvas.toDataURL("image/png");
            } catch (error) {
                console.error("Error capturing question:", error);
            }
        },

        async copyToClipboard(imageUrl) {
            const blob = this.dataURLtoBlob(imageUrl);
            navigator.clipboard.write([
                new ClipboardItem({
                    'image/png': blob
                })
            ]);
            this.$toasted.success("Successfully Copied to Clipboard.")
        },

        dataURLtoBlob(dataURL) {
            const [header, base64String] = dataURL.split(',');
            const mime = header.match(/:(.*?);/)[1];
            const binaryString = atob(base64String);
            const length = binaryString.length;
            const arrayBuffer = new Uint8Array(length);

            for (let i = 0; i < length; i++) {
                arrayBuffer[i] = binaryString.charCodeAt(i);
            }

            return new Blob([arrayBuffer], { type: mime });
        },

        async downloadPdf() {
            try {
                // Mock Google API PDF conversion (Google API integration would go here)
                // const pdfContent = await this.createMockPdfFromSlides();
                // this.savePdfFile(pdfContent);
                console.log("starting odf")
                var client = window.google.accounts.oauth2.initTokenClient({
                    scope: 'profile email https://www.googleapis.com/auth/drive',
                    client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
                    callback: async (user) => {
                        try {
                            console.log(user)
                            this.downloadPdfFromApi(user['access_token'])
                        } catch (error) {
                            this.isFirstConfigure = false;
                            console.log(error)
                            this.$toasted.error("Oops an Error Occurred. Email us at support@appeq.ai")
                        }
                    }
                })
                client.requestAccessToken((not) => {
                    console.log(not)
                });
            } catch (error) {
                console.error("Error downloading PDF:", error);
            }
        },

        // Mock PDF creation function
        async createMockPdfFromSlides() {
            return "mock-pdf-content"; // Replace with real API call
        },
        async downloadPdfFromApi(token) {
            try {
                this.loadingBtn = true
                // await this.auth.signIn(); // Authenticate the user
                const fileId = "1Aj9NGZBDdCx-6wJR6qb7G3xsTA6qNkCan_AySLMfiyM"; // Replace with the Google Slide file ID
                const exportLink = `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=application/pdf`;

                // const token = gapi.auth.getToken().access_token;
                const response = await fetch(exportLink, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const blob = await response.blob();
                    console.log(blob)
                    this.returnBlob = blob
                    this.loadingBtn = false

                    this.$emit("pdf-downloaded", { data: blob })
                    // this.savePdfFile(blob);
                } else {
                    console.error("Failed to export PDF:", response.statusText);
                }
            } catch (error) {
                console.error("Error exporting PDF:", error);
            } finally {
                this.loadingBtn = false
            }
        },

        savePdfFile(pdfContent) {
            const blob = new Blob([pdfContent], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "google-slides.pdf";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    height: 92vh;
    padding-right: 0px !important;
    max-width: 100% !important;
}

.left-section {
    width: 20%;
    padding: 10px;
    overflow-y: auto;
    border-right: 1px solid #ccc;
}

.right-section {
    width: 80%;
    padding: 10px;
}

.thumbnail-container {
    margin-bottom: 20px;
    text-align: center;
}

.thumbnail {
    width: 100%;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px;
}

.loading-overlay {
    position: fixed;
    left: 0px;
    right: 0px;
    background: white;
    top: 0px;
    opacity: 0.8;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}



.pdf-button {
    color: white;
    border: none;
    text-align: center;
    font-size: 16px;
    width: 155px;
    height: 35px;
    cursor: pointer;
    position: fixed;
    left: 25px;
    bottom: 25px;
}

.pdf-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
</style>