<template>
    <div style="height: 68vh; overflow: scroll; margin-top: 1rem;">
        <table style="width: 100%;">
            <tr>
                <th v-for="(col, idx) in cols" :style="{'width': col.width, 'text-align':(col.title=='Action') ? 'center':''}" style="padding: 10px;">
                    <div>{{ col.title }}</div>
                </th>
            </tr>
            <tr :class="{ 'recently-modified': isRecentlyModified(val) }" v-for="(val, idx) in data">
                <td v-for="col in cols">
                    <div style="font-size: 0.7vw;" v-tooltip.top-start="(showAllTooltips && val[col.title]) ? (val[col.title]&&val[col.title].length>120 ? val[col.title].substr(0, 120)+'...':val[col.title]):''" v-if="col.title!='Action' && !col.parameter">
                        {{ (val[col.title] ? (val[col.title]).length>50 ? (val[col.title]).substr(0,50)+'...':val[col.title] : 'NA') }}
                    </div>
                    <div v-else-if="(col.parameter && col.parameter.length>0) && col.title!='Action'">
                        <div style="display: flex; align-items: center;" v-if="col.parameter.includes('email') && col.parameter.includes('name') && col.parameter.includes('logo')">
                            <div>
                                <img v-if="val['profile_url']" style="height: 30px; width: 30px; display: flex; justify-content: center; align-items: center; text-align: center; margin-right: 10px; border-radius: 50%; font-size: 12px;" :src="val['profile_url']" alt="">
                                <div v-else style="background: #6f55ff; height: 30px; width: 30px; text-align: center; color: white; border-radius: 50%; margin-right: 10px; display: flex; align-items: center; justify-content: center; font-size: 12px;">
                                    {{ val['email_id'].toString().split('@')[0].split('')[0].toUpperCase() }}
                                </div>
                            </div>
                            <div>
                                <div style="font-size: 14px; font-weight: 600; text-transform: capitalize;">{{ val['email_id'].toString().split('@')[0] }}</div>
                                <div style="font-size: 12px; color: grey;">{{ val['email_id'] }}</div>
                            </div>
                        </div>
                    </div>
                    <div v-else style="display: flex; align-items: center; justify-content: center;">
                        <div v-for="ele in val['Action']" style="margin-left: 0.5vw;">
                            <img v-if="ele.img.toLowerCase().includes('configureicon')" @click="handleAction(ele.action, idx)" v-tooltip="ele.hover" src="../../assets/ConfigureIcon.png" style="height: 1vw; cursor: pointer;" alt="">
                            <img v-tooltip="ele.hover" @click="handleAction(ele.action, idx)" v-else-if="ele.img.toLowerCase().includes('view')" src="../../assets/View.png" style="height: 1.2vw; opacity: 0.8; cursor: pointer;" alt="">
                            <img src="../../assets/edit.png" v-else-if="ele.img.toLowerCase().includes('edit')" v-tooltip="ele.hover" @click="handleAction(ele.action, idx)" style="height: 1vw; cursor: pointer; filter: invert(0%) sepia(89%) saturate(7458%) hue-rotate(327deg) brightness(-8%) contrast(110%);" alt="">
                            <img src="../../assets/delete.svg" v-else-if="ele.img.toLowerCase().includes('delete')" v-tooltip="ele.hover" @click="handleAction(ele.action, idx)" style="height: 1vw; cursor: pointer;" alt="">
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    props: {
        columns: [],
        showAllTooltips: false,
        tableData: []
    },
    data(){
        return {
            cols: [],
            data: []
        }
    },
    watch:{
     tableData :function()  {
            this.getConvertedColumns();
        }
    },
    methods: {
        isRecentlyModified(data){
            const twoMinutesAgo = 5.55 * 60 * 1000 * 60;  
            console.log(new Date().getTime(),new Date(data['updatedAt']).getTime())
            const timeDifference = new Date().getTime() - (new Date(data['updatedAt'])).getTime();
            console.log(timeDifference,"---0990")
            return ( timeDifference)<=twoMinutesAgo;

        },
        getConvertedColumns(){
            //mapping the data for nested objects
            let tableData = this.mapDataToColumns(this.tableData, this.columns);
            this.data = tableData;
            this.cols = this.columns;
        },
        getNestedValue(obj, key) {
            return key?.split('.')?.reduce((o, i) => (o ? o[i] : undefined), obj);
        },
        mapDataToColumns(data, columns) {
            return data.map(item => {
                let mappedItem = {...item};
                columns.forEach(column => {
                    mappedItem[column.title] = this.getNestedValue(item, column.key);

                });
                return mappedItem;
            });
        },
        handleAction(action, idx){
            this.$emit('handleAction', [action, this.tableData[idx], idx]);
        }
    },
    async mounted(){
        console.log(this.columns, this.tableData);
        this.getConvertedColumns();
    }
}
</script>
<style scoped>
td{
    padding: 15px;
}
.recently-modified{
    font-weight: 700 !important;
    /* background-color: #efefef; */
}
</style>