<template>
    <div>
      <div v-if="!loading && current==0" style="display: flex; flex-direction: column;">
        <h6 style="margin-top: 1.5rem !important;">Enter your API Key</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter API Key" v-model="apiKey"/>
        </div>
        <h6 style="margin-top: 1.5rem !important;">Enter your API Token</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text"style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter Email" v-model="apiToken"/>
        </div>
        <h6 style="margin-top: 1.5rem !important;">Enter your Account SID</h6>
        <div style="border: 1px solid #dce2ea; display: flex; height: 40px;align-items: center;">
          <input type="text" style="border: none;font-size: 12px;font-weight: 300; width: 100%;" placeholder="Enter url" v-model="accountSid"/>
        </div>
        <button class="connect_button" @click="authenticateJira">Authenticate</button>
      </div>
      <div v-else-if="!loading && current==2">
        <h5 class="successText">
          You have successfully configured Exotel
        </h5>
        <img src="../assets/success.svg" />
    </div>
      <div v-else style="display: flex; align-items: center; justify-content: center;">
        <img src="../icons/loadingripple.svg" style="height: 50px;" alt="">
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  import Server from "./API/Server.js";
  
  export default {
    data(){
      return {
        apiKey: '',
        email: '',
        apiToken:"",
        accountSid:"",
        baseUrl: '',
        current: 0,
        jiraOptions: [],
        projectKey: null,
        isSuccess: false,
        loading: false
      }
    },
    props: {
      selectedBobj: ''
    },
    components: {
      Multiselect
    },
    methods: {
      async connectJira() {
        try {
          this.loading = true;
          if(!this.projectKey) {
            this.loading = false;
            return;
          }
          let resp = await Server.connectJira(this.email, this.apiKey, this.baseUrl, this.projectKey.key);
          console.log(resp);
          this.current = 2;
          this.$toasted.success("Jira credentials saved");
          this.loading = false;
          setTimeout(() => {
          window.location.reload()
        }, 2000);
        } catch (error) {
          this.$toasted.error("Access not given by Jira")
          this.loading = false;
        }
      },
      async authenticateJira() {
        try {
          this.loading = true;
          if(this.apiKey === '') {
            this.loading = false;
            return;
          }
          let res = await Server.authenticateExotel(this.apiKey, this.apiToken, this.accountSid);
          console.log(res);
          if(res.data.code !== 200) {
            this.$toasted.error('Some error occurred');
            this.loading = false;
            return;
          }
        //   this.jiraOptions = res.data.projects;
        const objs = localStorage.getItem("Business_obj_name").split(',');
          await Promise.all(objs.map(async d=>
            await Server.saveExotel(this.apiKey, this.apiToken, this.accountSid,d)
          ))
          this.current = 2;
          this.loading = false;
          setTimeout(() => {
          window.location.reload()
        }, 2000);
        } catch (error) {
          this.loading = false;
        }
      }
    },
    async mounted() {
      console.log(this.selectedBobj, 'business object');
    }
  }
  </script>
<style scoped>
.connect_button{
    border: 1px solid blue; border-radius: 6px; background: white; color: black; height: 40px; margin: 10px 0px;
}
.connect_button:hover{
    background: rgb(88, 88, 255); color: white;
}
</style>