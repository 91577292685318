<template>
  <div class="email-template-configuration">
    <h4 style="text-align: center;margin-top: 20px;margin-bottom: 3px;">
      Email Template
    </h4>
    <img @click="cancel()" src="../assets/img/feed_close.svg"
      style="cursor: pointer; font-size: 25px;position: absolute;right: 0px;top: 0px;" alt="">
    <!-- <div class="tabs">
        <button class="tab" @click="selectTab('configuration')">Configuration</button>
      </div> -->

    <div v-if="selectedTab === 'configuration' && !loading" class="configuration">
      <div style="display: flex;align-items: center;justify-content: space-around;" class="category">
        <label style="font-size: 14px;font-weight: 600;">
          <input type="radio" name="templateType" value="text" v-model="templateType" />
          Static template
        </label>
        <label style="font-size: 14px;font-weight: 600;">
          <input disabled type="radio" name="templateType" value="cgpt" v-model="templateType" />
          Gen AI prompt template <span style="font-size: 10px;color: blue;margin-left: 5px">(Coming Soon)</span>
        </label>
      </div>
      <div class="testimonials">
        <div class="cc-bcc">
          <div class="inputBox">
            <span>Name :</span>
            <input type="text" placeholder="Enter Your Template Name" v-model="name" />

          </div>
          <!-- <input type="text" placeholder="CC (comma separated emails)" v-model="ccEmails" /> -->
        </div>
      </div>

      <div class="email-form">
        <div v-if="templateType == 'text'" style="height: 70vh; overflow: scroll;">
          <div class="cc-bcc">
            <div class="inputBox">
              <span>Subject : </span>
              <input type="text" placeholder="Subject" v-model="bccEmails" />
            </div>
            <!-- <input type="text" placeholder="CC (comma separated emails)" v-model="ccEmails" /> -->
          </div>
          <div class="email-body">
            <div ref="editor" class="quill-editor"></div>
          </div>
          <div style="display: flex;align-items: center;margin-top: 20px;position: absolute;bottom: 30px;width: 80%;">
            <img v-tooltip="'Insert Dynamic Variable'" src="../icons/variable.png"
              style="height: 22px;margin-left: 10px; cursor: pointer;"
              @click="openMoreOptions = !openMoreOptions; openSuggestType('user')" alt="">
              <img 
                @click="openAttachment=!openAttachment"
                v-tooltip="'Configure Attachments'"
                style="height: 22px;margin-left: 10px; cursor: pointer;"
                src="../icons/Attachment.png" />
            <div style="margin-left: 20px;width: 100%;" class="" v-if="openAttachment">
            <input v-model="attachmentLink" style="border:none !important;border-bottom: 1px solid #efefef !important;width: 80%;" type="text" placeholder="Enter Google Slide Link"/>
          </div>
          </div>
         
          <div v-if="openMoreOptions" @click="openMoreOptions = false;"
            style="position: fixed; top: 0%; left: 0%; height: 100%; width: 100%;">
            <div
              style="height: 34vh; border: 1px solid #b3b3b3; box-shadow: 0 2px 10px rgba(0,0,0,0.1); border-radius: 6px; width: 24vw; position: fixed; left: 21%; bottom: 17%; background: aliceblue;z-index: 9999;">
              <div style="display: flex; background: white; height: 45px; border-radius: 6px 6px 0px 0px;" @click.stop>
                <div @click="openSuggestType('contacts')"
                  style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;"
                  :style="{ 'border-bottom': (selectedSuggestType == 'contacts') ? '3px solid blue' : 'none', 'font-weight': (selectedSuggestType == 'contacts') ? '600' : '' }">
                  Contacts</div>
                <div @click="openSuggestType('user')"
                  style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;"
                  :style="{ 'border-bottom': (selectedSuggestType == 'user') ? '3px solid blue' : 'none', 'font-weight': (selectedSuggestType == 'user') ? '600' : '' }">
                  User</div>
              </div>
              <div style="height: 28vh; overflow-x: hidden; overflow-y: scroll;">
                <div v-for="item in suggestedOptions" @click="handleItemFromSuggestion(item.key, 'text')"
                  style="display: flex; cursor: pointer; align-items: center; margin: 10px; padding: 10px; font-size: 16px;">
                  <!-- <div style="flex: 0.05;"></div> -->
                  <div style="color: blue; font-weight: 600; flex: 0.4;">
                    {{ item.key }}
                  </div>
                  <div style="flex: 0.2;"></div>
                  <div style="font-weight: 600; flex: 0.4;">
                    {{ item.value }}
                  </div>
                  <div @click="copyToClipboard(item.key)" class="">
                    <img v-tooltip="'Copy Variable'" src="../icons/clipboard.png" style="height: 16px;cursor: pointer;"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="height: 49vh;">
          <div class="email-body">
            <textarea rows="12" v-model="prompt">{{ prompt }}</textarea>
          </div>
          <div v-if="openMoreOptions" @click="openMoreOptions = false;"
            style="position: fixed; top: 0%; left: 0%; height: 100%; width: 100%;">
            <div
              style="height: 34vh; border: 1px solid #b3b3b3; box-shadow: 0 2px 10px rgba(0,0,0,0.1); border-radius: 6px; width: 24vw; position: fixed; left: 21%; bottom: 17%; background: aliceblue;">
              <div style="display: flex; background: white; height: 45px; border-radius: 6px 6px 0px 0px;" @click.stop>
                <div @click="openSuggestType('contacts')"
                  style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;"
                  :style="{ 'border-bottom': (selectedSuggestType == 'contacts') ? '3px solid blue' : 'none', 'font-weight': (selectedSuggestType == 'contacts') ? '600' : '' }">
                  Contacts</div>
                <div @click="openSuggestType('user')"
                  style="cursor: pointer; padding-bottom: 5px; margin: 10px; margin-bottom: 0px; font-size: 16px; color: black;"
                  :style="{ 'border-bottom': (selectedSuggestType == 'user') ? '3px solid blue' : 'none', 'font-weight': (selectedSuggestType == 'user') ? '600' : '' }">
                  User</div>
              </div>
              <div style="height: 28vh; overflow-x: hidden; overflow-y: scroll;">
                <div v-for="item in suggestedOptions" @click="handleItemFromSuggestion(item.key, 'ai')"
                  style="display: flex; cursor: pointer; align-items: center; margin: 10px; padding: 10px; font-size: 16px;">
                  <!-- <div style="flex: 0.05;"></div> -->
                  <div style="color: blue; font-weight: 600; flex: 0.4;">
                    {{ item.key }}
                  </div>
                  <div style="flex: 0.2;"></div>
                  <div style="font-weight: 600; flex: 0.4;">
                    {{ item.value }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;align-items: center;">
            Dynamic Variable <img v-tooltip="'Insert Dynamic Variable'" src="../assets/brackets.png"
              style="height: 22px;margin-left: 10px; cursor: pointer;"
              @click="openMoreOptions = !openMoreOptions; openSuggestType('user')" alt="">
          </div>
        </div>

        <div class="actions">
          <!-- <button @click="cancel"
            style="background-color: white; color: black; border: 1px solid #5f5f5f;">Cancel</button> -->
          <button @click="save">Save</button>
        </div>
      </div>
    </div>
    <div v-else-if="selectTab === 'configuration' && loading"
      style="display: flex; justify-content: center; align-items: center;">
      <img src="../assets/loadingRipple.svg" style="height: 200px;" alt="">
    </div>


    <!-- Logs tab content would go here -->

  </div>
</template>

<script>
import Server from "./API/Server";
export default {
  data() {
    return {
      selectedTab: 'configuration',
      templateType: 'text',
      openMoreOptions: false,
      selectedSuggestType: 'user',
      suggestedOptions: [],
      editingStatus: false,
      quillEditor:null,
      name: '',
      description: '',
      loading: false,
      openAttachment:false,
      ccEmails: '',
      attachmentLink:"",
      bccEmails: '',
      emailContent: ``,
      prompt: '',
      promptId: '',
      defaultEmailContent: ''
    };
  },
  props: {
    promptdata: {},
    isEditing: false
  },
  async created() {
    const wait = (ms) => new Promise((res) => setTimeout(res, ms));
    wait(500).then(d => {
      // this.handleInitialize()
      this.quillEditor = new Quill(this.$refs.editor, {
        theme: 'snow', // You can change the theme to 'bubble' if you prefer a different style
        placeholder: 'Enter body here .....',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], // B, I, U buttons
            ['link'] // Link button
          ]
        }
      });
      console.log(this.promptdata)
      if (this.promptdata && this.promptdata['templateBody']) {
      this.quillEditor.clipboard.dangerouslyPasteHTML(this.promptdata['templateBody']);
    }
      this.quillEditor.on('text-change', () => {
        this.promptdata['templateBody']=this.quillEditor.root.innerHTML
        this.emailContent = this.quillEditor.root.innerHTML;
        // this.onChangeNotes()
      });
    })
  },
  methods: {
    copyToClipboard(data){
      navigator.clipboard.writeText(data)
    },
    openReadMore() {
      window.open("https://help.appeq.ai/en/articles/9013085-email-assist")
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    handleItemFromSuggestion(val, type) {
      if (type == 'ai') {
        this.prompt += val;
      } else {
        if (!this.emailContent || !this.defaultEmailContent) {
          this.defaultEmailContent = val;
          this.emailContent = val;
        } else {
          this.defaultEmailContent += val;
          this.emailContent += val;
        }
      }
      this.openMoreOptions = false;
    },
    async openSuggestType(val) {
      if (val == 'user') {
        this.suggestedOptions = [
          { key: '{{user.first_name}}', value: 'Rajat' },
          { key: '{{user.last_name}}', value: 'Shukla' },
          { key: '{{user.company}}', value: 'AppEQ.ai' },
          { key: '{{user.email}}', value: 'rajat@appeq.ai' },
        ];
      } else {
        let data = (await Server.getFieldsApi('Contacts')).data;
        if (data && data.data) {
          this.suggestedOptions = [];
          data.data = data.data.sort((a, b) => {
  if (a.key < b.key) return -1;
  if (a.key > b.key) return 1;
  return 0;
});          for (let i = 0; i < data.data.length; i++) {
            this.suggestedOptions.push({ key: '{{' + data.data[i].key + '}}' });
          }
        }
        // this.suggestedOptions = [];
      }
      this.selectedSuggestType = val;
    },
    toggleCCBCC() {

      // Logic to toggle CC/BCC fields
    },
    cancel() {
      // Logic to handle cancel
      this.$emit("close")
    },
    async save() {

      // Logic to handle save
      if (!this.templateType || !this.name) {
        return;
      }
      if (this.templateType == 'text') {
        if (!this.bccEmails || !this.emailContent) {
          return;
        }
        let data = {
          'templateSubject': this.bccEmails,
          'templateBody': this.emailContent,
          'attachmentLink':this.attachmentLink
        };
        this.loading = true;
        let response = {};
        if (this.editingStatus) {
          response = await Server.savePrompt(this.name, this.templateType, data, this.promptId, true);
        } else {
          response = await Server.savePrompt(this.name, this.templateType, data);
        }
        console.log(response);
        if (response.code == 200) {
          this.loading = false;
          this.$toasted.success('Successfully saved your template');
          this.$emit('close');
        }
      } else {
        if (!this.prompt) {
          return;
        }
        let data = {
          'prompt': this.prompt
        };
        this.loading = true;
        let response = {};
        if (this.editingStatus) {
          response = await Server.savePrompt(this.name, this.description, this.templateType, data, this.promptId, true);
        } else {
          response = await Server.savePrompt(this.name, this.description, this.templateType, data);
        }
        console.log(response);
        if (response.code == 200) {
          this.loading = false;
          this.$toasted.success('Successfully saved your template');
          this.$emit('close');
        }
      }
    }
  },
  mounted() {
    if (this.promptdata != {}) {
      if (this.isEditing) {
        this.editingStatus = this.isEditing;
      }
      this.name = this.promptdata.name;
      if (this.promptdata.promtType == 'CGPT') {
        this.templateType = 'cgpt';
        this.prompt = this.promptdata.prompt;
        this.promptId = this.promptdata.promptId;
        this.description = this.promptdata.description;
      } else {
        this.templateType = 'text';
        this.bccEmails = this.promptdata.templateSubject;
        this.defaultEmailContent = this.promptdata.templateBody;
        this.emailContent = this.promptdata.templateBody;
        this.promptId = this.promptdata.promptId;
        this.description = this.promptdata.description;
        this.attachmentLink=this.promptdata.attachmentLink
      }
    }
    this.defaultEmailContent = this.emailContent;
    setTimeout(() => {
      document.querySelector(".email-template-configuration").style.right = "0px"

    }, 200)
  }
};
</script>

<style scoped>
.email-template-configuration {
  font-family: 'Arial', sans-serif;
  margin: 0px auto;
  z-index: 88888;
  transition: right 1s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
  border: 1px solid rgb(236, 236, 236);
  background: #fff;
  border-radius: 8px;
  position: fixed;
  right: -80vw;
  width: 80vw;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100vh;
  top: 0px;
  overflow-y: scroll;
}

.tabs {
  display: flex;
  color: black !important;
}

.tab {
  flex: 1;
  padding: 10px;
  color: black !important;
  text-align: center;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: #e6e6e6;
}

.configuration {
  padding: 45px;
  padding-top: 12px !important;
}

.testimonials {}

.testimonials h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.testimonials p {
  font-size: 14px;
  color: #666;
}

.category label {
  display: block;
  margin: 10px 0;
}

.category input[type='radio'] {
  margin-right: 10px;
}

.cc-bcc {
  display: flex;
  gap: 10px;
  align-items: center;
  margin: 4px 0;

  label {
    flex: 0.05;
  }
}

.cc-bcc input[type='text'] {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 0px;
}

.email-body {
  /* display: flex; */
  /* align-items: center; */
  height: 45vh !important;
  label {
    flex: 0.05;
  }
}

.email-body textarea {
  padding: 10px;
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 150px;
  font-size: 14px;
  margin-left: 0px;

  margin-bottom: 20px;
}

.actions {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.actions button {
  margin-left: 10px;
  height: 35px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  transition: background-color 0.3s;
}

.cancelBtn {
  border: 1px solid #4e5fff;
}

.actions button:hover {
  opacity: 0.7;
}

button:focus {
  outline: none;
}

button:active {
  transform: scale(0.98);
}

/* Specific button styles */
button {
  color: white;
}

button:nth-child(1) {}

button:nth-child(2) {}

.inputBox {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #efefef;

  span {
    font-weight: 600;
  }

  input {
    border: none !important;
    width: 100%;

    &:focus {
      border: none !important;
    }
  }

}
.ql-toolbar.ql-snow{
    border: none !important;
}
.quill-editor.ql-container.ql-snow{
    border: none !important;

}
p{
  color: black !important;
}
</style>
<style lang="scss">
.ql-editor{
    border: none !important;
    height: 45vh !important;
    padding-left: 0px !important;
}

.ql-toolbar.ql-snow {
  position: fixed;
  bottom: 45px;
  // z-index: 99;
  width: 11% !important;
  border-top: 1px solid #ccc;
}
.ql-editor.ql-blank::before {
left: 0px !important;
}
.ql-toolbar.ql-snow{
    border: none !important;
    padding-left: 0px !important;
}
p{
  color: black !important;
}
strong{
  font-weight: 700 !important;
}
</style>