<template>
    <el-card shadow="hover" class="card-container">
      <div class="mb-4">
        <h2 class="heading">Customer Dashboard Configuration</h2>
        <p class="subheading">Manage the dashboard assignments for each customer.</p>
      </div>
  
      <!-- Loading and Error State -->
      <el-alert v-if="loading" type="info" :closable="false" class="mb-4">
        Loading customers...
      </el-alert>
      <el-alert v-if="error" type="error" :closable="false" class="mb-4">
        {{ error }}
      </el-alert>
  
      <!-- Customer Table -->
      <el-table v-if="customers.length" :data="customers" border stripe class="table-container">
        <el-table-column
          v-for="column in columns"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
        ></el-table-column>
        <el-table-column label="Actions">
          <template v-slot="scope">
            <el-button type="primary" size="small" @click="openDashboardModal(scope.row)">
              Edit Dashboard
            </el-button>
          </template>
        </el-table-column>
      </el-table>
  
      <!-- Dashboard Configuration Modal -->
      <el-dialog :visible.sync="showModal" title="Configure Dashboard" width="70%" @close="closeModal">
        <span v-if="loadingDashboards">Loading available dashboards...</span>
        <div v-else>
            <div class="dashboard-list">
            <div 
              v-for="(config, index) in allConf" 
              :key="index"
              class="dashboard-select-item"
            >
              <div class="select-wrapper">
                <span class="dashboard-number">Dashboard {{index + 1}}</span>
                <el-select
                  v-model="config.metabaseNumber"
                  placeholder="Select a dashboard"
                  class="dashboard-select"
                >
                  <el-option
                    v-for="dashboard in availableDashboards"
                    :key="dashboard.id"
                    :label="dashboard.name"
                    :value="dashboard.id"
                  ></el-option>
                </el-select>
                    {{ config.users }}
                <el-button 
                  type="danger" 
                  icon="el-icon-delete" 
                  size="mini" 
                  @click="removeDashboardConfig(index)"
                  class="remove-btn"
                ></el-button>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
          class="cancel-button"

          @click="closeModal">Cancel</el-button>
          <el-button
          class="save-button"

          type="primary" @click="saveDashboardConfiguration" :disabled="!selectedDashboardId">
            Save
          </el-button>
        </span>
      </el-dialog>
    </el-card>
  </template>
  
  <script>
  import axios from 'axios';
  import { JWT } from "node-jsonwebtoken";
import Server from '../components/API/Server';
  
  export default {
    name: 'CustomerDashboardConfig',
    data() {
      return {
        customers: [],
        loading: false,
        error: null,
        showModal: false,
        allConf:[],
        dashboards:[],
        selectedCustomer: null,
        selectedDashboardId: '',
        availableDashboards: [],
        loadingDashboards: false,
        columns: [
          { key: 'name', label: 'Customer Name' },
          { key: 'id', label: 'Customer ID' },
          { key: 'metabaseDashboardId', label: 'Current Dashboard' },
        ],
      };
    },
    methods: {
      async fetchCustomers() {
        this.loading = true;
        try {
          const response = await axios.post('https://api1.appeq.ai/get-customers', {}, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-API-Token': 'appeq-lens-1234567890'
            }
          });
          this.customers = response.data.data;
        } catch (err) {
          this.error = 'Failed to load customers: ' + err.message;
        } finally {
          this.loading = false;
        }
      },
  
      async generateMetabaseToken() {
      const METABASE_SECRET_KEY = '0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779';
      const payload = {
        resource: { dashboard: null },
        params: {},
        exp: Math.floor(Date.now() / 1000) + (60 * 60 * 48)
      };
      
      try {
        // Use the correct jwt.sign method
        const jwt=new JWT(METABASE_SECRET_KEY)
        return await jwt.sign(payload);
      } catch (error) {
        console.error('Error generating token:', error);
        throw new Error('Failed to generate Metabase token');
      }
    },

    async getMetabaseSession() {
      try {
        const token = await this.generateMetabaseToken();
        // First, establish a session with Metabase
        const sessionResponse = await axios.post('https://app.appeq.ai/bi/api/session', {
          token: token
        });
        
        return sessionResponse.data.id; // Metabase returns a session ID
      } catch (error) {
        console.error('Error getting Metabase session:', error);
        throw new Error('Failed to authenticate with Metabase');
      }
    },

    async fetchMetabaseDashboards() {
      this.loadingDashboards = true;
      this.error = null;
      
      try {
        
        const response = await axios.get('https://app.appeq.ai/bi/api/dashboard', {
          headers: {
            'x-api-key': 'mb_qfGzUZ1+iCbnOQhvy3D47hZ88EBMXf38V+W8Dqe7amc='
          }
        });
        
        this.availableDashboards = response.data;
        
      } catch (err) {
        console.error('Dashboard fetch error:', err);
        this.error = 'Failed to load dashboards: ' + (err.response?.data?.message || err.message);
      } finally {
        this.loadingDashboards = false;
      }
    },
  
      openDashboardModal(customer) {
        this.selectedCustomer = customer;
        this.selectedDashboardId = customer.metabaseDashboardId || '';
        this.showModal = true;
        this.fetchCustomerData(customer.name)
        this.fetchMetabaseDashboards(); // Fetch dashboards when modal opens
      },
      async fetchCustomerData(customer){
        const data=(await Server.getAllowedApps2("system@"+customer)).data
        console.log(data,"data")
        if(data && data.data && data.data.dashboards){
            this.allConf=data.data.dashboards

        }
      },
  
      closeModal() {
        this.showModal = false;
        this.selectedCustomer = null;
        this.selectedDashboardId = '';
      },
  
      async saveDashboardConfiguration() {
        try {
          await axios.post('YOUR_API_ENDPOINT/save-dashboard-config', {
            customerId: this.selectedCustomer.id,
            dashboardId: this.selectedDashboardId
          });
  
          const customerIndex = this.customers.findIndex(c => c.id === this.selectedCustomer.id);
          if (customerIndex !== -1) {
            this.customers[customerIndex] = {
              ...this.customers[customerIndex],
              metabaseDashboardId: this.selectedDashboardId
            };
          }
  
          this.closeModal();
        } catch (err) {
          this.error = 'Failed to save dashboard configuration: ' + err.message;
        }
      }
    },
    mounted() {
      this.fetchCustomers();
    }
  };
  </script>
  
  <style scoped>
  .card-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
  }
  
  .heading {
    font-size: 1.5rem;
    font-weight: 600;
    color: #2d3748;
    margin-bottom: 0.5rem;
  }
  
  .subheading {
    font-size: 1rem;
    color: #718096;
    margin-bottom: 1.5rem;
  }
  
  .table-container {
    margin-top: 1.5rem;
  }
  
  .dashboard-select {
    margin-top: 1rem;
  }
  
  .dialog-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }
  .save-button{
    width: 45%;
  }
  .cancel-button{
    width: 30%;
  }
  .remove-btn{
    width: 20%;
  }
  </style>
  