<template>
    <div class="nudge-config">
      <h2>Configure Monthly Business Review Nudge</h2>
  
      <!-- Nudge Day Selection -->
      <label for="nudgeDay">Select the Day of the Month to Trigger Nudge:</label>
     
      <Multiselect v-model="nudgeDay" :options="daysInMonth" />
      <!-- Email Template -->
      <!-- <input v-model="templateName" id="templateName" type="text" placeholder="Template Name" /> -->
  
      <label for="googleSlidesLink">Google Slides Link:</label>
      <input v-model="googleSlidesLink" id="googleSlidesLink" type="text" placeholder="Google Slides Link" />
    
      <!-- Submit Button -->
      <button @click="saveNudgeConfig">Save Configuration</button>
  
      <!-- Display Saved Data -->
      <div v-if="showConfirmation" class="confirmation">
        <h3>Configuration Saved!</h3>
       
      </div>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect"
  export default {
    components:{
        Multiselect
    },
    data() {
      return {
        nudgeDay: 1,
        templateName: '',
        googleSlidesLink: '',
        emailSubject: '',
        emailBody: '',
        showConfirmation: false
      };
    },
    computed: {
      daysInMonth() {
        return Array.from({ length: 31 }, (_, i) => i + 1); // Generates an array [1, 2, 3, ..., 31]
      }
    },
    methods: {
      saveNudgeConfig() {
        // Save the data (You can replace this with an API call to save data on the server)
        this.showConfirmation = true;
        console.log('Nudge configuration saved:', {
          nudgeDay: this.nudgeDay,
          templateName: this.templateName,
          googleSlidesLink: this.googleSlidesLink,
          emailSubject: this.emailSubject,
          emailBody: this.emailBody
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .nudge-config {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .nudge-config label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .nudge-config input,
  .nudge-config select,
  .nudge-config textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .nudge-config button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .nudge-config button:hover {
    background-color: #0056b3;
  }
  
  .confirmation {
    margin-top: 20px;
    background-color: #d4edda;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #c3e6cb;
  }
  
  .confirmation a {
    color: #007bff;
    text-decoration: none;
  }
  </style>
  