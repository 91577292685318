<template>
  <div>
    <div class="image">
      <img height="50px" :src="imageUrl.logoURL" />
    </div>
    <div style="margin-bottom:30px;margin-bottom: 30px;
                background: #e8e8e8;
                border-radius: 5px;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;"
      >
      <span style="color:black; font-size: 15px;">Adding {{ imageUrl.productName }} for-:</span>
      <span v-for="i,index of selectedBuisnessObjectList" style="color: blue !important; font-size: 15px;">
        {{ i }} <span style="color:black" v-if="index != selectedBuisnessObjectList.length-1">,</span>
      </span> 
    </div>
    <div v-if="!isSuccess">
      <h6 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
       <span id="data_source_zendesk_subdomain_div">How do I find my subdomain</span>   
      </h6>
      <h5 v-if="imageUrl.productName == 'Zendesk Support Suite'">
        <span>
          Enter your {{ imageUrl.productName }}
        {{ imageUrl.productName == "Google Sheet" ? "Link" : "Subdomian" }}
        </span>
      </h5>
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <div class="domainbox">https://</div>
        <input v-model="zendeskDomain" />
        <div class="domainbox">.zendesk.com</div>
      </div>
      <h5 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        <span id="data_source_zendesk_email_div">Enter your zendesk email</span>
      </h5>
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <input v-model="zendeskEmail" />
      </div>
      <h5 class="mt-3" v-if="imageUrl.productName == 'Zendesk Support Suite'">
        <span id="data_source_zendesk_token_div">Enter your zendesk token</span>
      </h5>
      <div v-if="imageUrl.productName == 'Zendesk Support Suite'" class="zendeskInputBox">
        <input v-model="zendeskToken" />
      </div>
    </div>
    <div class="success mt-4" v-if="isSuccess">
      Your zendesk creds are successfully verified
    </div>
    <GoogleSheetDescPage v-if="imageUrl.productName == 'Google Sheet' || imageUrl.productName == 'spreadsheet'"></GoogleSheetDescPage>
    <SalesForceDesc  v-if="imageUrl.productName == 'Salesforce'"></SalesForceDesc>
    <HubspotDesc v-if="imageUrl.productName.toString().toLowerCase().includes('hubspot')"></HubspotDesc>
    <ZohoDesc v-if="imageUrl.productName.toString().toLowerCase().includes('zoho')"></ZohoDesc>
    <FreshDeskDescPage v-if="imageUrl.productName.toLowerCase().includes('freshdesk')"></FreshDeskDescPage>
    <ChargeBeeDEsc v-if="imageUrl.productName.toLowerCase().includes('chargebee')"></ChargeBeeDEsc>

    <FreshsalesDescPage :selectedBobj="bObj" v-if="imageUrl.productName=='Freshsales'"></FreshsalesDescPage>
    <ApiIngesionDesc :image-url="imageUrl" v-if="imageUrl.productName == 'API Ingestion'"></ApiIngesionDesc>
    <ProductUsage v-if="imageUrl.productName.toLowerCase().includes('mixpanel')"></ProductUsage>
    <MicrosoftDynamics v-if="imageUrl.productName == 'Dynamics 365 Sales'"></MicrosoftDynamics>
    <ExoteDesc v-if="imageUrl.productName=='Exotel'"></ExoteDesc>
    <JiraDescPage :selectedBobj="bObj" v-if="imageUrl.productName.toLowerCase().includes('jira')"></JiraDescPage>
    <RocketlaneDescPage :selectedBobj="bObj" v-if="imageUrl.productName.toLowerCase().includes('rocketlane')"></RocketlaneDescPage>
    <LogicERPDescPage :selectedBobj="bObj" v-if="imageUrl.productName.toLowerCase().includes('logic erp')"></LogicERPDescPage>
    <button @click="submitButtonclick()"
      v-if="imageUrl.productName == 'Zendesk Support Suite' && !isSuccess && !isLoading">
      Submit
    </button>
    <div v-if="isLoading" class="loading">
      <img src="../assets/infinity_blue.svg" />
    </div>
    <span v-if="!isSuccess" class="error">{{ error }}</span>
  </div>
</template>

<script>
import GoogleSheetDescPage from "./GoogleSheetDesc.vue";
import Server from "./API/Server.js";
import SalesForceDesc from "./SalesForceDesc.vue";
import FreshDeskDescPage from "./FreshDeskDescPage.vue";
import FreshsalesDescPage from "./FreshsalesDescPage.vue";
import LocalUtils from "./Utils/LocalUtils";
import HubspotDesc from "./HubspotDesc.vue";
import ApiIngesionDesc from "./ApiIngesionDesc.vue";
import ProductUsage from "./ProductUsage.vue"
import JiraDescPage from "./JiraDescPage.vue";
import mixpanel from "../analytics/mixpanel";
import ZohoDesc from "./ZohoDesc.vue";
import MicrosoftDynamics from "./MicrosoftDynamics.vue";
import RocketlaneDescPage from "./RocketlaneDescPage.vue";
import LogicERPDescPage from "./LogicERPDescPage.vue";
import ExoteDesc from "./ExoteDesc.vue";
import ChargeBeeDEsc from "../pages/ChargeBeeDEsc.vue";

export default {
  name: "DescPage",
  methods: {
    handleIClick(key) {
      console.log(key);
      this.$set(this.isActive, key, !this.isActive[key])
      console.log(this.isActive);
    },
    async submitButtonclick() {

      if(this.zendeskEmail=='' || this.zendeskDomain=='' || this.zendeskToken==''){
        this.$toasted.error("Please Fill All Fields First");
        return
      }

      this.isLoading = true
      const data = await Server.zendeskSubmit({
        adminEmail: LocalUtils.getEmail(),
        email: this.zendeskEmail,
        domain: this.zendeskDomain,
        token: this.zendeskToken,
        appeq_supported_application: "zendesk"
      });
      //Server.callSceduler("ticket","");

      try {
        mixpanel.businessObjectAdded(localStorage.getItem("Business_obj_name"));
      } catch (e) {
        console.log(e);
      }
      this.$toasted.success("Please wait we will notify you once the data has been resynced via mail .")

      this.isLoading = false
      if (!data.error) this.isSuccess = true;
      else this.error = "Your credentials are invalid";

      window.location.reload();
    },
  },
  async mounted() {
    localStorage.removeItem("ALL_CREDS")
    //  this.isAdmin= localStorage.getItem("IS_ADMIN");
    this.selectedBuisnessObjectList = localStorage.getItem("Business_obj_name").split(',');
    console.log(this.selectedBuisnessObjectList);
    console.log(this.$router.currentRoute.path == '/integrations');
    this.bObj = this.selectedBuisnessObjectList;
    console.log(this.bObj, 'kuch aarha hai yaha pe');
    try {
      const data = await Server.zendeskCredsFetch();
      if (data && data.data) {
        this.zendeskEmail = data.data.zendesk_email;
        this.zendeskDomain = data.data.zendesk_domain;
        this.zendeskToken = data.data.zendesk_token;
      }
      // if(!this.isAdmin){
      //   // this.zendeskToken="X".repeat(this.zendeskToken.length-4)+this.zendeskToken.substring(this.zendeskToken.length-4)
      //   // this.zendeskEmail="X".repeat(this.zendeskEmail.length-4)+this.zendeskEmail.substring(this.zendeskEmail.length-4)
      //   // this.zendeskDomain="X".repeat(this.zendeskDomain.length-2)+this.zendeskDomain.substring(this.zendeskDomain.length-2)

      // }
    }
    catch (error) {
      this.$toasted.error(error);
    }
  },
  components: {
    GoogleSheetDescPage,
    SalesForceDesc,
    HubspotDesc,
    ChargeBeeDEsc,
    FreshDeskDescPage,
    FreshsalesDescPage,
    ProductUsage,
    ZohoDesc,
    JiraDescPage,
    ExoteDesc,
    RocketlaneDescPage,
    LogicERPDescPage,
    MicrosoftDynamics,
    ApiIngesionDesc
  },
  data() {
    return {
      isActive: [false, false, false],
      isLoading: false,
      zendeskEmail: "",
      bObj: '',
      
      zendeskDomain: "",
      zendeskToken: "",
      isAdmin: false,
      isSuccess: false,
      error: "",
      selectedBuisnessObjectList:[],
    };
  },
  props: {
    imageUrl: {
      type: Object,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>

span{
  color: black !important;
}
.zendeskInputBox {
  border: 1px solid rgb(220, 226, 234);
  display: flex;
  height: 40px;
  align-items: center;

  input {
    width: 100%;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
}

.domainbox {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(244, 246, 250);
}

button {
  flex-shrink: 0;
  background: #6f55ff;
  color: white;
  border: 0px;
  padding: 0px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 56px;
  cursor: pointer;
  border-radius: 6px;
  width: 100%;
  margin-top: 20px;
}

.error {
  color: red !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px !important;

  img {
    object-fit: contain;
  }
}

.success {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

i:hover {
  scale: 1.2;
  transition: 0.2s ease-in-out;
}

.iconIsActive {
  scale: 2;
  transition: 0.2s ease-in-out;
  border-radius: 50%;

}

.icon-alert-circle-exc {
  position: absolute;
  margin-top: 5px;
  right: 30px;
  transition: 0.2s ease-in-out;
  color: blue;
}

.support {
  left: 350px;
  height: 100px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-top: 15px;
  padding: 20px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-transform: none;
  font-size: 12px;
  font-weight: 550;
  border-radius: 10px;

}

.support2 {
  left: 350px;
  height: 310px;
  width: 320px;
  background: whitesmoke;
  position: absolute;
  margin-left: -30px;
  margin-top: -20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
</style>
