<template>
  <div class="compose-email">
    <header class="compose-header">
      <div style="display: flex;align-items: center;width: 45%;" class="">
        <h3 style="margin-bottom: 0px;font-size: 14px;font-weight: 600;flex: 0.4;">New Message</h3>
        <div style="flex: 1;display: flex;align-items: center;" class="">
          <span @click="handleChangeTemplates" v-if="!showTemplate" v-tooltip="'Change template'"
            style="margin-left: 12px;margin-right: 10px;cursor: pointer;color: blue;">{{ emailData['assist'] &&
              emailData['assist']['name'] ? emailData['assist']['name'] :'Choose template'}}</span>
          <Multiselect v-model="emailData['assist']" v-if="showTemplate" label="name" :options="templates"
            @select="handleSelectTemplate" />
          <img style="height: 16px;cursor: pointer;margin-left: 6px" v-if="showTemplate" @click="handleChangeTemplates"
            src="../icons/check-mark.png" />
        </div>

      </div>
      <div style="display: flex;align-items: center;" class="">
        <div @click="sendEmail()" v-tooltip="'Send a test mail to yourself'" class="test-btn"
          style="background: none !important;color: blue;margin-left: 10px;cursor: pointer;border: none !important; font-size: 9px;margin-right: 50px;">
          Send Test Email to My Inbox</div>
        <img @click="closeCompose" style="font-size: 14px;cursor: pointer;" src="../icons/crossButton.svg" />

      </div>

      <!-- <button class="close-btn" @click="closeCompose">✕</button> -->
    </header>
    <div class="form-body">

      <!-- To Field -->
      <div class="form-group">
        <!-- <input
        type="text"
        v-model="emailData.to"
        placeholder="To"
        class="email-input"
      /> -->
        <div class="" style="display: flex;align-items: center;">
          <Multiselect :taggable="true" @tag="addTag($event, index)" @select="handleChange" v-model="emailData['to']"
            style="width: 30% !important;" placeholder="To" :options="toOptions" />
          <Multiselect :taggable="true" @tag="addTag($event, index)" @select="handleChange" v-model="emailData['cc']"
            style="width: 30% !important;margin-left: 20px" placeholder="CC" :options="toOptions" />
        </div>

        <!-- <button class="cc-bcc-toggle" @click="toggleCcBcc">Cc Bcc</button> -->
      </div>

      <!-- Subject Field -->
      <div class="form-group">
        <input type="text" v-model="emailData.subject" placeholder="Subject" class="email-input" />
      </div>

      <!-- Email Body Field -->
      <div class="form-group">
        <div class="loading-container" v-if="loading">
          <img src="../assets/img/preloader5.gif" style="height: 12vh;" alt="" />
        </div>
        <!-- <textarea style="border: none !important;border-bottom: none !important;height: 45vh;"
          v-model="emailData['body']" rows="20" class="email-body"></textarea> -->
        <div ref="editor" class="quill-editor"></div>
        <!-- Toolbar -->

      </div>
    </div>
    <!-- <Modal class="generate-modal"  :show="openGenerateModal" v-if="openGenerateModal"> -->
    <div v-if="openGenerateModal" class="generate-model">
      <GenerateModal @pdf-downloaded="handlePdfDownloaded($event)" :params="emailparams"
        @close="openGenerateModal = false" :dashboardId="emailparams['dashboardId'] ? emailparams['dashboardId'] : '561'" />

    </div>
    <!-- </Modal> -->
    <div style="display: flex;align-items: center;margin-left: 25px">
      <div v-if="pdfBlob != null" style="display: flex;align-items: center;margin-right: 14px !important" class="">
        <img @click="handleView"
          src="https://play-lh.googleusercontent.com/hoJp176QsxUS8k5O0i0VeX6V5QX1ywx90YxPckWjyjN7_qXwMBhSMdH6TkPwci9mkw"
          style="height: 16px;margin-right: 10px;" />
        <span @click="handleView" style="color: blue;cursor: pointer;">Business Review Report</span>
      </div>
      <div v-if="fileAttachment != null">
        <img v-tooltip="'View Attached PDF'" style="height: 18px;"
          src="https://play-lh.googleusercontent.com/hoJp176QsxUS8k5O0i0VeX6V5QX1ywx90YxPckWjyjN7_qXwMBhSMdH6TkPwci9mkw"
          @click="viewPdf" />
        <span @click="viewPdf" style="color: blue;cursor: pointer;margin-left: 8px;">{{ fileAttachment.name }}</span>

      </div>
    </div>
    <!-- Footer with Send Button -->
    <footer class="compose-footer">

      <div style="position: fixed;
    left: 16vw;
    bottom: 56px;
    height: 20px;
    border-left: 1px solid #9d9a9a;
    padding-left: 19px;
    display: flex;
    align-items: center;" class="">
        <img v-tooltip="'Attach Files'" style="height: 18px;" src="../icons/attachment.svg"
          @click="handleAttachmentClick" />
        <input type="file" ref="fileInput" @change="handleFileChange" accept="application/pdf" style="display: none;" />
        <img v-tooltip="'Configure Attachment '" @click="handleGenerateEmail" style="height: 18px;margin-left: 10px;"
          src="../icons/Attachment.png" />
        <img v-tooltip="'Trends & Charts Library - Coming Soon'" style="height: 18px;margin-left: 10px;"
          src="../icons/trends.png" />

      </div>
      <div style="display: flex;align-items: center;" class="">

        <button v-if="!sending" class="send-btn" @click="sendEmail(emailData['to'])">Send</button>
        <div v-else>
          <img src="../assets/img/Progress2.gif" style="height: 28px;" />
        </div>
        <!-- <div @click="sendEmail()" v-tooltip="'Send a test mail to yourself'" class="test-btn"
          style="background: none !important;color: blue;margin-left: 10px;cursor: pointer;border: none !important; font-size: 12px;">
          Test Email</div> -->

      </div>
    </footer>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect"
import Server from "../components/API/Server";
import Modal from "../components/Modal.vue"
import GenerateModal from "./GenerateModal.vue";
import moment from "moment";
export default {
  components: {
    Multiselect,
    Modal,
    GenerateModal
  },
  props: {
    emailparams: {},
    pdfBlobProps:null
  },
  async mounted() {
    try {
      this.loading = true
      if(this.pdfBlobProps){
        this.pdfBlob=this.pdfBlobProps
      }
      this.myEmail = localStorage.getItem("__email__id")
      console.log(this.emailparams)
      if (this.emailparams && this.emailparams['email_id']) {
        this.emailData['to'] = decodeURIComponent(this.emailparams['email_id'])
      }
      if (this.emailparams && this.emailparams['assistId']) {
        const emailForm = await Server.getpromptsForDashboard()
        if (emailForm && emailForm['data'] && Array.isArray(emailForm['data'])) {
          this.templates = emailForm['data'];
          const findById = emailForm['data'].filter(d => d.promptId == this.emailparams['assistId'])
          this.emailData['subject'] = findById[0]['templateSubject']
          this.emailData['body'] = findById[0]['templateBody']
          this.emailData['name'] = findById[0]['name']
          this.emailData['assist'] = findById[0]
          this.quillEditor = new Quill(this.$refs.editor, {
            theme: 'snow', // You can change the theme to 'bubble' if you prefer a different style
            placeholder: 'Enter body here .....',
            modules: {
              toolbar: [
                ['bold', 'italic', 'underline'], // B, I, U buttons
                ['link'] // Link button
              ]
            }
          });

          console.log(this.emailData['body'])
          if (this.emailData['body']) {
            this.emailData['body'] = this.replacePlaceholders(this.emailData['body'], {})

            this.quillEditor.clipboard.dangerouslyPasteHTML(this.emailData['body']);
            this.quillEditor.on('text-change', () => {
              this.emailData['body'] = this.quillEditor.root.innerHTML
              // this.emailContent = this.quillEditor.root.innerHTML;
              // this.onChangeNotes()
            });
          }

        }

      } else {
        const emailTemplates = await Server.getpromptsForDashboard()
        if (emailTemplates && emailTemplates['data'] && Array.isArray(emailTemplates['data'])) {
          this.templates = emailTemplates['data'];
        }
        this.loading = false;
        this.noTemplatesFound = true;
      }
      const contactOptions = await Server.getContacts(
        {
          "$and": [{ account_domain: { "$eq": this.emailparams['domain'] } }]
          , business_object_name: "Contacts",
          email: localStorage.getItem("__email__id")
        }, 1)
      this.contacts = contactOptions.data
      this.toOptions = contactOptions.data.map(d => d.email)
      if (this.emailparams && this.emailparams['email_id']) {
        this.handleChange()
        // this.emailData['to'] = decodeURIComponent(this.emailparams['email_id'])
      }
      this.loading = false

    } catch (error) {
      console.log(error)
    }

  },
  data() {
    return {
      emailData: {
        to: "",
        subject: "",
        body: "",
      },
      sending: false,
      contacts: [],
      noTemplatesFound: false,
      loading: false,
      myEmail: "",
      fileAttachment: null,
      toOptions: [],
      downloadPdf: "",
      templates: [],
      openGenerateModal: false,
      pdfBlob: null,
      showTemplate: false,
      isDownloading: false,
      ccBccVisible: false,
    };
  },
  methods: {
    handleSelectTemplate(event) {
      const findById = event
      this.emailData['subject'] = findById['templateSubject']
      this.emailData['body'] = findById['templateBody']
      this.emailData['name'] = findById['name']
      this.emailData['assist'] = findById

      this.quillEditor = new Quill(this.$refs.editor, {
        theme: 'snow', // You can change the theme to 'bubble' if you prefer a different style
        placeholder: 'Enter body here .....',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'], // B, I, U buttons
            ['link'] // Link button
          ]
        }
      });
      if (this.emailData['body']) {
        this.emailData['body'] = this.replacePlaceholders(this.emailData['body'], {})
        this.handleChange()

        this.quillEditor.clipboard.dangerouslyPasteHTML(this.emailData['body']);
        this.quillEditor.on('text-change', () => {
          this.emailData['body'] = this.quillEditor.root.innerHTML
          // this.emailContent = this.quillEditor.root.innerHTML;
          // this.onChangeNotes()
        });
      }

    },
    handleChangeTemplates() {
      this.showTemplate = !this.showTemplate
    },
    viewPdf() {
      const fileUrl = URL.createObjectURL(this.fileAttachment); // Create a URL for the file
      window.open(fileUrl, '_blank'); // Open the PDF in a new tab
    },
    handleAttachmentClick() {
      // Option 1: Let the user attach an existing PDF file
      this.$refs.fileInput.click();

      // Option 2: Prompt the user to generate a PDF with input
      // this.showPdfInput = true;
    },

    // Handles file selection when user chooses a PDF file
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        console.log("Attached PDF:", file);
        this.fileAttachment = file
        // You can save this file or perform additional actions here
      }
    },

    handlePdfDownloaded(event) {
      this.pdfBlob = event.data
      this.openGenerateModal = false
    },
    handleView() {
      const blob = new Blob([this.pdfBlob], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "google-slides.pdf";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    handleGenerateEmail() {
      this.openGenerateModal = true;
    },
    addTag(newTag, index) {
      const parts = newTag.split(', ');

      const tag = newTag;
      this.toOptions.push(tag);
      this.emailData['to'] = tag
      // this.selectedBObjecte.push(tag)
    },
    handleChange() {
      const currentContact = this.contacts.filter(d => d['email'] == this.emailData['to'])
      console.log("IN HANDLE CHANGE")
      this.emailData['subject'] = this.replacePlaceholders(this.emailData['subject'], currentContact.length > 0 ? currentContact[0] : {})

      this.emailData['body'] = this.replacePlaceholders(this.emailData['body'], currentContact.length > 0 ? currentContact[0] : {})
      this.quillEditor.clipboard.dangerouslyPasteHTML(this.emailData['body']);

    },
    replacePlaceholders(template, data) {
      const pattern = /\{\{([^}]+)\}\}/g;
      const matches = template.match(pattern);
      console.log("replace")
      if (matches) {
        matches.forEach(match => {
          const key = match.slice(2, -2).trim();
          const placeholder = `{{${key}}}`;
          console.log(key, "--key")
          if (key.includes("current_month")) {
            template = template.replace(new RegExp(placeholder, 'g'), moment(new Date()).format("MMM"));

          }
          if (key.includes("user.")) {
            const vars = key.split(".")[1]
            if (vars == 'first_name') {
              const emailPrefix = localStorage.getItem("__email__id").split("@")[0];
              const formattedPrefix = emailPrefix.charAt(0).toUpperCase() + emailPrefix.slice(1).toLowerCase();

              template = template.replace(new RegExp(placeholder, 'g'), formattedPrefix);
            }
            if (vars == 'company') {
              template = template.replace(new RegExp(placeholder, 'g'), localStorage.getItem("__email__id").split("@")[1]);

            }
            if (vars == 'email') {
              template = template.replace(new RegExp(placeholder, 'g'), localStorage.getItem("__email__id"));

            }
          }
          if (data.hasOwnProperty(key)) {
            template = template.replace(new RegExp(placeholder, 'g'), data[key]);
          } else {
            // template = template.replace(new RegExp(placeholder, 'g'), '');
          }
        });
      }
      console.log(template)

      return template;
    },

    async handleDownloadAttachment() {

      try {
        if (this.emailparams && this.emailparams['dashboardId']) {
          this.isDownloading = true;
          console.log(this.emailparams['params'])
          // Fetch PDF data from the server with the correct response type
          const response = await Server.downloadPdf(this.emailparams['dashboardId'], this.emailparams['params']);

          // Check if the response contains valid Blob data
          // Create a URL for the Blob object
          const url = window.URL.createObjectURL(response.data);
          window.open(url, "_blank")
          // Create a temporary anchor element for downloading the PDF
          // const a = document.createElement('a');
          // a.href = url;
          // a.download = 'export.pdf';  // Specify the name for download

          // // Append the anchor to the body and trigger the download
          // document.body.appendChild(a);
          // a.click();

          // // Cleanup the DOM and Blob URL
          // document.body.removeChild(a);
          // window.URL.revokeObjectURL(url);
        }

      } catch (error) {
        console.error('Error downloading PDF:', error.message || error);
      } finally {
        this.isDownloading = false;
      }
    },
    async handleSendEmailTest(toEmail) {
      const attachments = []
      if (this.pdfBlob) {
        attachments.push(this.pdfBlob)
      }
      if (this.fileAttachment) {
        attachments.push(this.fileAttachment)

      }
      let resp = (await Server.sendEmail(this.emailData['subject'], toEmail ? toEmail : localStorage.getItem('__email__id'), this.emailData['body'], localStorage.getItem('__email__id'), attachments)).data;
      console.log(resp);
      if (resp && resp.code != 200) {
        const client = window.google.accounts.oauth2.initTokenClient({
          scope: 'https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
          client_id: '180578786917-pkbmnkbqd229un30jc1kstkkp0sbn9te.apps.googleusercontent.com',
          access_type: 'offline', // Request a refresh token
          prompt: 'consent',
          callback: async (tokenResponse) => {
            if (!tokenResponse.access_token) {
              console.error('Error obtaining access token');
              toast.error("Error while sending email");
              return;
            }

            try {
              this.sending = true;
              // Store the Gmail token for the user
              await Server.updateUserInfo({
                email_id: localStorage.getItem('__email__id'),
                gmailToken: tokenResponse.access_token
              });

              // Send the email
              const attachments = []
              if (this.pdfBlob) {
                attachments.push(this.pdfBlob)
              }
              if (this.fileAttachment) {
                attachments.push(this.fileAttachment)

              }
              let resp = (await Server.sendEmail(this.emailData['subject'], toEmail ? toEmail : localStorage.getItem('__email__id'), this.emailData['body'], localStorage.getItem('__email__id'), attachments)).data;
              console.log(resp);

              // Check if email was successfully sent and show a success message
              if (resp && resp.data.id) {
                if (toEmail != localStorage.getItem("__email__id")) {
                  if (this.emailparams['task']) {
                    this.emailparams['task']['status'] = 'complete'
                    await Server.completeTasks(this.emailparams['task'])

                  }
                }
                this.sending = false;
                this.$toasted.success(
                  "Email sent Successfully"
                );
                if(toEmail){
          this.$emit("close")
        }

              }
            } catch (error) {
              console.error('Error while sending email:', error);
              this.$toasted.error("Error while sending email");
            }
          }
        });

        // Initiate the token request process
        client.requestAccessToken();
      } else if (resp.code == 200) {
        this.$toasted.success("Email send successfully")
        if(toEmail){
          this.$emit("close")
        }
      }

    },
    sendEmail(toEmail) {
      console.log("Email Sent", this.emailData);
      this.handleSendEmailTest(toEmail)

      // Clear form after sending
      // this.emailData = { to: "", subject: "", body: "" };
    },
    closeCompose() {
      this.$emit("close")
      // Logic to close compose window
      console.log("Compose closed");
    },
    toggleCcBcc() {
      this.ccBccVisible = !this.ccBccVisible;
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Google+Sans&display=swap");

.compose-email {
  font-family: 'Google Sans', sans-serif;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
  /* padding: 16px; */
  /* max-width: 600px; */
  /* margin: 0 auto; Center the component */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compose-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #ddd;
  background-color: #f2f2f2;
}

.compose-header h3 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #333;
}

.form-group {
  margin-bottom: 12px;
  /* padding: 12px; */
}

.email-input,
.email-body {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
  outline: none;
  transition: border-bottom-color 0.3s;
}

.email-input:focus,
.email-body:focus {
  border-bottom-color: #1a73e8;
  /* Focus color */
}

.email-body {
  height: 150px;
  resize: vertical;
}

.cc-bcc-toggle {
  background: none;
  border: none;
  color: #1a73e8;
  cursor: pointer;
  font-size: 12px;
  margin-left: 8px;
  padding: 0;
}

.compose-footer {
  position: fixed;
  bottom: 45px;
  right: 80px;
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 12px;
}

.send-btn {
  margin-top: 5px;
  background-color: #4E5FFF;
  color: white;
  border: none;
  border-radius: 12px;
  width: 80px;
  height: 35px;
  margin-left: 14px;
  cursor: pointer;
  font-size: 14px;
}

.send-btn:hover {
  background-color: #155ab2;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .compose-email {
    max-width: 100%;
    padding: 12px;
  }

  .email-body {
    height: 100px;
  }

  .send-btn {
    padding: 8px 12px;
  }
}

.form-body {
  padding: 10px 20px;
}

.loading-container {
  position: fixed;
  left: 40vw;
  right: 40vw;
  top: 25vh;
}
</style>
<style lang="scss">
.generate-model {
  position: fixed;
  left: 0px !important;
  left: 0px !important;
  height: 100vh !important;
  width: 100vw !important;
  z-index: 9999999;
  background: white;
  top: -14px;
}

.ql-editor {
  height: 50vh !important;
}
</style>