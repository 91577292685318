import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import router from "./router/index";
import Toasted from "vue-toasted";
import VTooltip from "v-tooltip";

import BlackDashboard from "./plugins/blackDashboard";
import "./registerServiceWorker";
import OtpInput from "@bachdgvn/vue-otp-input";
import VueLocalForage from "vue-localforage";
import VueCryptojs from "vue-cryptojs";
import VueSplash from "vue-splash";
import Tabs from "vue-tabs-component";
import VueBigHeads from "vue-bigheads";
import VueLazyload from "vue-lazyload";
import VoerroTagsInput from "@voerro/vue-tagsinput";
// import VueConfirmDialog from "vue-confirm-dialog";

import VueSimpleAccordion from "vue-simple-accordion";
import "vue-simple-accordion/dist/vue-simple-accordion.css";
import VueToggles from "vue-toggles/dist/vue-toggles.ssr";
import "vue-toggles/dist/vue-toggles.ssr.css";
// import PrimeVue from 'primevue/config';

// import 'primevue/resources/themes/saga-blue/theme.css'; // Theme of your choice
// import 'primevue/resources/primevue.min.css';
// import 'primeicons/primeicons.css';


import {
  VeTable,
  VePagination,
  VeIcon,
  VeLoading,
  VeLocale,
} from "vue-easytable"; // import library
import VueJWT from 'vuejs-jwt'
import CKEditor from 'ckeditor4-vue';
import VueD3 from "vue-d3";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.use(VueD3)

Vue.use( CKEditor );



Vue.use(VueJWT, {})


Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeIcon);
Vue.use(VeLoading);

Vue.component("VueToggles", VueToggles);
// Vue.use(PrimeVue);

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error:
    "https://media.istockphoto.com/id/1185206568/vector/colorful-vector-design-template.jpg?s=612x612&w=0&k=20&c=O8vto6IUHoMbHILCzM3Qyo3wP34P-qZBBYrxDZ7HM_Q=",
  loading: "https://i.stack.imgur.com/kOnzy.gif",
  attempt: 1,
});
Vue.use(VueBigHeads);
// Vue.use(VueConfirmDialog);
// Vue.component("vue-confirm-dialog", VueConfirmDialog.default);

Vue.use(VueSimpleAccordion);

Vue.use(Tabs);

// console.log = function () {};
Vue.use(VueSplash);
// Vue.use(Toaster);
Vue.use(VueLocalForage);

Vue.component("v-otp-input", OtpInput);
Vue.component("tags-input", VoerroTagsInput);
Vue.use(VueCryptojs);

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(VTooltip);
Vue.use(RouterPrefetch);
Vue.use(Toasted, {
  position: "top-right",
  duration: 6000,
  singleton: true,
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

// for turning down console.log
// console.log = function () {};
