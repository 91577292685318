<template>
  <div v-if="!syncLoading" class="addTaskModal">
      <div class="addTaskModal__header">
          <h3 style="text-transform: uppercase;">Task</h3>
          <span>
              <img @click="closePopup" src="../icons/closePopup.svg" style="position: absolute;right: 10px;
              top: 10px;height: 30px; cursor: pointer;" />
          </span>
      </div>
      <div class="addTaskModal__body">
          <div class="tile">
              <div v-if="showAccountDomain" class="tile--section">
                  <Multiselect
                  @search-change="debouncedSearchChange"
                  :loading="loader" label="domain" v-model="tasks['account_domain']" :options="customers"
                      placeholder="Select Customer " />
              </div>

          </div>
          <div class="tile">
              <div class="tile--section">
                  <input v-model="tasks['task_name']" style="width: 100%;border: none;border-bottom: 1px solid #e8e8e8;"
                      placeholder="Enter Task Name" />
              </div>
              <!-- {{ tasks['duedate'] }} -->
              <div  role="tooltip" aria-label="Task due date" data-microtip-position="left" style="margin-left: 20px;width: 40%;" class="tile--section">
                  <input v-model="tasks['duedate']" type="datetime-local"
                      style="width: 100%;border: none;border-bottom: 1px solid #e8e8e8;" placeholder="Enter Due Date" />
              </div>
          </div>
          <div class="tile">
              <div style="width: 100%;" class="tile--section">
                  <textarea v-model="tasks['desc']" rows="6" style="width:100%;" placeholder="Enter task description" />
              </div>
          </div>
          <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div style="flex: 1;margin-right: 15px;">

                  <Multiselect v-model="tasks['task_type']" 
                      placeholder="Select the type of task"
                      :options="['EMAIL', 'CALL', 'TODO', 'MEETING', 'BUSINESS REVIEW']" />
              </div>
              <div role="tooltip" aria-label="Task Assignee" data-microtip-position="left"  style="flex: 1;margin-right: 15px;">

                  <Multiselect :loading="loader" v-model="tasks['assignee']"  placeholder="Assignee"
                      :options="users" />
              </div>

          </div>
          <div style="display: flex;justify-content: space-between;margin-top: 30px;margin-bottom: 20px;">
              <div style="display: flex;align-items: center;margin-left: 10px;" class="">
                  <!-- <div  role="tooltip" aria-label="Bi-Directional Sync with CRM" data-microtip-position="right" class="">
                      <img src="../icons/bidirectional.png" style="margin-right: 20px;height: 20px;cursor: pointer;"/>
                  </div> -->
                  <div  role="tooltip" aria-label="Delete - It will delete from CRM as well !" data-microtip-position="right" class="">

                  <img @click="handleDeleteTask" src="../icons/del.svg" style="margin-right: 20px;height: 16px;cursor: pointer;filter: invert(12%) sepia(87%) saturate(6183%) hue-rotate(4deg) brightness(93%) contrast(126%);"/>
                  </div>
              </div>
              <div style="display: flex;align-items: center;" class="">
                  <button @click="closePopup" class="cancelBtn">Cancel</button>
                  <button @click="handleNewSave" style="margin-left: 10px;">Save</button>

              </div>


          </div>

      </div>
  </div>
  <div v-else>
      <div class="addTaskModal__header">
          <h3 style="text-transform: uppercase;">Tasks</h3>
          <span>
              <img @click="closePopup" src="../icons/closePopup.svg" style="position: absolute;right: 10px;
              top: 10px;height: 30px; cursor: pointer;" />
          </span>
      </div>
      <div v-if="syncStatus == 'LOADING'"
          style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
          <div style="display: flex;align-items: center;">
              <img style="height: 120px;" src="../icons/appEQ-squareLogo-new.png" />
              <img style="height: 120px;" src="../icons/syncLoading.svg" />
              <img style="height: 120px;" :src="getIcon()" />

          </div>
          <div>
              Please wait while we are syncing task to your underline CRM
          </div>

      </div>
      <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;"
          v-else-if="syncStatus == 'ERROR'">
          <img style="height: 200px;" src="../icons/error.svg" />
          <div>
              Error occured while syncing <span style="color: red;">{{ errorMsg }}</span>
          </div>
      </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
// import Server from "../server/api"
import Server from './API/Server';
import moment from "moment"
// import { toast } from 'vue3-toastify';
// import AppEQAnalytics from "../analytics/appeqAnalytics"
import axios from 'axios';

export default {
  props: {
      taskSelected: { type: Object }
  },
  components: {
      Multiselect,

  },
  data() {
      return {
          oldtaskName: '',
          syncStatus: '',
          syncApplication: '',
          errorMsg: '',
          users: [],
          syncLoading: false,
          debouncedSearchChange: null, // Placeholder 
          tasks: {
              task_type: "", task_name: "", updated_date: new Date(), duedate: "", desc: "", account_domain: "", assignee: ""
          },
          showAccountDomain: true,
          customers: [],
          loader:false,

      }
  },
  async mounted() {
      this.debouncedSearchChange = this.debounce(this.onSearchChange, 300); // 300ms delay

      this.loader=true
      if (this.taskSelected) {
          this.tasks = this.taskSelected;
          this.oldtaskName = this.tasks['task_name']

          this.showAccountDomain = false
      } else {
          const timestamp = new Date().getTime();
          const uniqueId = timestamp.toString() + Math.floor(Math.random() * 1000);
          // this.tasks['id']=parseInt(uniqueId, 10);
          // this.customers = (await Server.getAccountsData()).data.map(d => d)
      }
      this.users = (await Server.getAllUsers2()).data.map(d => d.email_id)
     
      if(!this.tasks['duedate']){
          const today = new Date();
          this.tasks['duedate'] = today.toISOString().split('T')[0];
      }else{
          const dueDate = new Date(this.tasks['duedate']);
      const formattedDate = dueDate.toISOString().split('T')[0];
      this.tasks['duedate'] = formattedDate;
      }
      this.loader=false

  },
  methods: {
      debounce(func, delay) {
let timeout;
return function (...args) {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    func.apply(this, args);
  }, delay);
};
}
,
      async onSearchChange(searchQuery){
          if (searchQuery.length > 2) { // Trigger search only for queries with more than 2 characters
      this.loader = true; // Show loading spinner

      // Simulate API call
     const data=await Server.getAllAllowedDomainsforDashboard(searchQuery,1,10)
     console.log(data)
     if(data && data.data){
      this.customers=data.data
     }
     this.loader=false
    }
      },
      async handleDeleteTask() {
          try {
              await Server.deleteTasks(this.taskSelected)
              toast.success("Task deleted successfully")
              this.$emit("save")
          } catch (error) {
              console.log(error)
              toast.error(error.toString())
          }
      },
      getIcon() {
          try {

              if (this.syncApplication == 'hubspot')
                  return 'https://logo.clearbit.com/' + 'hubspot' + ".com";
              else
                  return 'https://logo.clearbit.com/' + 'salesforce' + ".com";

          } catch (e) {
              console.log(e);
          }
      },
      closePopup() {
          this.$emit("close")
      },
      async handleNewSave(){
          try {
          this.syncLoading=true
          this.syncStatus="LOADING"
          this.tasks['priority']='Normal'
          const domain = this.tasks["account_domain"]["domain"]
          const id = this.tasks["account_domain"]["id"]
          this.tasks['account_domain']=domain
          this.tasks['account_id']=id
          this.tasks['status']='open'
         this.tasks['via']= {
                              "application": "AppEQ",
                              createdBy: localStorage.getItem("__email__id")
                          }

          const taskData=await Server.saveNewTasks(this.tasks)
          if(taskData && taskData.code==200)
          this.$toasted.success(taskData.message)
          else
          this.$toasted.error(taskData.message)
          console.log(taskData);
          
      } catch (error) {
              this.$toasted.error(error.toString())
          }finally{
              this.syncLoading=false;
              this.$emit("save")

          }

      },
      async handleSave() {
          try {
              if (!this.showAccountDomain) {
                  const domain = this.tasks["account_domain"]
                  const id = this.tasks["id"]
                  let query = { account_id: id, "account_domain": domain }

                  delete this.tasks["account_domain"]
                  query = { ...query, ...this.tasks, "method": "force", old_task_name: this.oldtaskName }

                  const creds = await Server.getBusinessDataObs()
                  if (creds.data && creds.data.findIndex(d => d.business_object_name == 'tasks') > -1) {
                  const idx = creds.data.findIndex(d => d.business_object_name == 'tasks')
                  this.syncApplication = query['appeq_supported_application']
                  console.log(this.syncApplication)
                  if (this.syncApplication == 'salesforce') {
                      this.handleAddToSalesforce(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], creds.data[idx]['instance_url'], id, query,"PATCH")
                      } else if (this.syncApplication == 'hubspot') {
                      this.handleAddToHubspot(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], id, query)
                      } 
                      else{
                          if (query) {
                          
                          const data = await Server.saveTasks(query)
                          if (data.code == 200) {
                              toast.success("Tasks saved successfully")
                              this.$emit("save")
                          } 

                      }
                      }
              }else {
                  console.log("Tasks not found ")
                      if (query) {
                          
                          
                          const data = await Server.saveTasks(query)
                          if (data.code == 200) {
                              toast.success("Tasks saved successfully")
                              this.$emit("save")
                          } 

                      }
                  }
                  return;
              }
              if (!this.tasks["account_domain"]["domain"]) {
                  toast.error("Sorry,Task needs to be associated to a customer. Please select a customer to go ahead")

                  return;
              }
              // console.log(this.tasks)
              // delete this.tas
              const domain = this.tasks["account_domain"]["domain"]
              const id = this.tasks["account_domain"]["id"]
              const email = localStorage.getItem("__email__id")
              // return;
              let query = { account_id: id, account_domain: domain }
              if (!query["account_id"] || !query["account_domain"] || !this.tasks["desc"] || !this.tasks["task_name"] || !this.tasks["duedate"]) {
                  toast.error("Sorry,Task cannot be empty please fill all the fields to save task")
                  return
              }
              delete this.tasks["account_domain"]
              query = { ...query, ...this.tasks }
              console.log(query)
              const creds = await Server.getBusinessDataObs()
              if (creds.data.findIndex(d => d.business_object_name == 'tasks') > -1) {
                  const idx = creds.data.findIndex(d => d.business_object_name == 'tasks')
                  this.syncApplication = creds.data[idx]['appeq_supported_application']
                  if (this.syncApplication == 'salesforce') {
                      this.handleAddToSalesforce(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], creds.data[idx]['instance_url'], id, query)
                  } else if (this.syncApplication == 'hubspot') {
                      this.handleAddToHubspot(creds.data[idx]['access_token'], creds.data[idx]['refresh_token'], id, query)
                  }else{
                      if (query) {
                          query["via"] = {
                              "application": "AppEQ",
                              createdBy: localStorage.getItem("__email__id")
                          }
                          const data = await Server.saveTasks(query)
                          if (data.code == 200) {
                              toast.success("Tasks saved successfully")
                              this.$emit("save")
                          } else {
                              toast.error("Error occured while saving tasks.")
                          }

                      }
                  }
              }else {
                      if (query) {
                          query["via"] = {
                              "application": "AppEQ",
                              createdBy: localStorage.getItem("__email__id")
                          }
                          const data = await Server.saveTasks(query)
                          if (data.code == 200) {
                              toast.success("Tasks saved successfully")
                              this.$emit("save")
                          } else {
                              toast.error("Error occured while saving tasks.")
                          }

                      }
                  }

          } catch (error) {
              console.log(error)
              if (error)
                  toast.error(error.toString())
              else
                  toast.error("Sorry,Something went wrong . Please try again after some time.")

          }
          // await Server.saveTasks
      },
      async handleAddToHubspot(accessToken, refreshToken, id, query) {
          const clientId = '6951dc5d-cab1-43b6-a76e-34a85d504058';
          const domain = "mailshake.com"
          const vm = this;
          this.syncLoading = true
          this.syncStatus = 'LOADING'
          // const callBack=()=>{this.successCustomer=true}
          try {

              const tokenUrl = 'https://api.hubapi.com/oauth/v1/token';
              const clientSecret = 'ad944f03-0a0f-49ae-a289-847851ed0f2e';

              const data = {
                  grant_type: 'refresh_token',
                  client_id: clientId,
                  client_secret: clientSecret,
                  refresh_token: refreshToken
              };

              let accessToken = await (await fetch(tokenUrl, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                  },
                  body: new URLSearchParams(data)
              })).json()
              accessToken = accessToken.access_token;
              console.log('Access Token:', accessToken);
              const apiUrl = 'https://api.hubapi.com/crm/v3/owners';
              const owners = (await axios.get(apiUrl, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`
                  }
              })).data
              console.log(owners)
              const user = owners.results.findIndex(d => d.email == this.tasks['assignee']);
              const userId = owners.results[user].id
              console.log(`User ID: ${userId}`);
              const properties = {
                  properties: {
                      "hs_timestamp": vm.tasks['duedate'],
                      "hs_task_body": vm.tasks['task_name'],
                      "hubspot_owner_id": userId,
                      "hs_task_subject": vm.tasks['desc'],
                      "hs_task_status": "WAITING",
                      "hs_task_priority": "LOW"
                  },
                  associations: [{
                      to: {
                          "id": id
                      },
                      "types": [
                          {
                              "associationCategory": "HUBSPOT_DEFINED",
                              "associationTypeId": 192
                          }
                      ]
                  }],
              };

              const addCompanyUrl = 'https://api.hubapi.com/crm/v3/objects/tasks';
              const addComp = await axios.post(addCompanyUrl, {

                  ...properties
              }, {
                  headers: {
                      'Authorization': `Bearer ${accessToken}`,
                      'Content-Type': 'application/json'
                  },
              })
              console.log(addComp)
              vm.syncLoading = false;
              toast.success("Task added to your CRM application")
              if (query) {
                  query["via"] = {
                      "application": "hubspot",
                      createdBy: localStorage.getItem("__email__id")
                  }
                  const data = await Server.saveTasks(query)
                  if (data.code == 200) {
                      toast.success("Tasks saved successfully")
                      vm.$emit("save")
                  } else {
                      toast.error("Task added to salesforce error occured while syncing back to AppEQ.")
                  }

              }
              vm.$emit("save")

          } catch (error) {
              console.log(error)
              vm.syncStatus = 'ERROR'
              vm.errorMsg = error

          }
      },
      async handleAddToSalesforce(accessToken, refreshToken, instanceUrl, accountId, query,method) {
          const clientId = '3MVG9wt4IL4O5wvLgb9I5m8DvdLV3B_WpFrNAM6bHFe9w6BqDaJTyoQ2pI4JsP8LqwJ98EyTODeYtVFvLX91S';
          const clientSecret = '148F2E93D23C83B291CDB7197CC31EDED6E11B5FED7D55F04603957A7937045B';
          const redirectUri = chrome.identity.getRedirectURL();
          const vm = this;
          this.syncLoading = true;
          this.syncStatus = 'LOADING';


          const tokenUrl = 'https://login.salesforce.com/services/oauth2/token';
          const data = {
              grant_type: 'refresh_token',
              client_id: clientId,
              client_secret: clientSecret,
              refresh_token: refreshToken
          };

          fetch(tokenUrl, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              },
              body: new URLSearchParams(data)
          })
              .then(response => response.json())
              .then(async tokenData => {
                  const accessToken = tokenData.access_token;
                  console.log('New Access Token:', accessToken);

                  // Now you can use the new access token to interact with Salesforce API

                  const taskEndpoint = instanceUrl +( '/services/data/v53.0/sobjects/Task/') + (method?query["Id"]:'');
                  // const userListEndpoint = instanceUrl+'/services/data/v53.0/query/?q=SELECT+Id,Email+FROM+User';
                  const userList = await Server.getOwnersLis()
                  console.log(userList)
                  let ownerId = ''
                  userList.data.map(d => {
                      if (d.Email == this.tasks['assignee']) {
                          ownerId = d.Id
                      }
                  })
                  if (!ownerId) {
                      toast.error("Current user does not belong to salesforce")
                      vm.syncStatus = 'ERROR'
                      vm.errorMsg = 'Current user does not belong to salesforce'
                      return;
                  }
                  const taskData = {
                      Subject: this.tasks['task_name'],
                      Description: this.tasks['desc'],
                      WhatId: accountId, // Replace with the actual Lead ID
                      Status: 'Open',
                      Priority: 'Normal',
                      OwnerId: ownerId,
                      ActivityDate: this.tasks['duedate']
                  };
                  console.log("Task endpoint is "+ taskEndpoint)
                  fetch(taskEndpoint, {
                      method: method?method:'POST',
                      headers: {
                          'Authorization': `Bearer ${accessToken}`,
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify(taskData)
                      })
                      .then(response =>method?response.ok: response.json())
                      .then(async responseData => {
                          console.log('Task created successfully:', responseData);
                          if(method){
                              query["via"] = {
                                  "application": "salesforce",
                                  createdBy: localStorage.getItem("__email__id")
                              }
                              query["method"]="force"
                              const data = await Server.saveTasks(query)
                              if (data.code == 200) {
                                  toast.success("Tasks edited successfully")
                                  vm.$emit("save")
                              } else {
                                  toast.error("Task added to salesforce error occured while syncing back to AppEQ.")
                              }
                              return
                          }
                          query['Id'] = responseData['Id']
                          // Handle success
                          vm.syncLoading = false;
                          if(method){
                              query["via"] = {
                                  "application": "salesforce",
                                  createdBy: localStorage.getItem("__email__id")
                              }
                              query["method"]="force"
                              const data = await Server.saveTasks(query)
                              if (data.code == 200) {
                                  toast.success("Tasks edited successfully")
                                  vm.$emit("save")
                              } else {
                                  toast.error("Task added to salesforce error occured while syncing back to AppEQ.")
                              }
                          }
                          if (query) {
                              query["via"] = {
                                  "application": "salesforce",
                                  createdBy: localStorage.getItem("__email__id")
                              }
                              const data = await Server.saveTasks(query)
                              if (data.code == 200) {
                                  toast.success("Tasks saved successfully")
                                  vm.$emit("save")
                              } else {
                                  toast.error("Task added to salesforce error occured while syncing back to AppEQ.")
                              }

                          }

                          // Additional code as needed
                      })
                      .catch(error => {
                          console.log('Error creating task:', error);
                          // Handle error
                          vm.syncLoading = false;
                          vm.syncStatus = 'ERROR'
                          vm.errorMsg = error
                          // Additional code as needed
                      });

              })
              .catch(error => {
                  console.log('Error obtaining new access token:', error);
                  vm.syncStatus = 'ERROR';
                  vm.errorMsg = error
              });
      }

  }
}
</script>
<style lang="css" scoped>
.tile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tile--section {
  width: 55%;
  display: flex;
  align-items: start;
  flex-direction: column;
}

input:focus {
  outline: none;
  border: none;
}

textarea:focus {
  outline: none;
  border: none;
}

button {
  margin-left: 20px;

  background: #4e5fff;
  color: #fff;
  border: 0;
  padding: 0 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  height: 30px;
  cursor: pointer;
  border-radius: 6px;
  width: 80px;
  margin-top: -10px;
}

.cancelBtn {
  border: 1px solid #4e5fff;
  background-color: transparent;
  color: #4e5fff;
}

textarea {
  width: 100%;
  border: none;
  border-bottom: 1px solid #e8e8e8;
}
</style>