<template>
    <div class="playbook card">
        <div class="playbook__header">
            <span style="font-size: 40px;" class="material-symbols-outlined">
alternate_email
</span>
            <!-- <img class="playbook__header__img" src="../assets/playbook.png" /> -->
            <div style="width: 60%;margin-left: 20px;" class="">
                <h3 class="playbook__header__title">Email Templates</h3>
                <span class="playbook__header__desc">Email Templates are re-usable templates which can be used by users in your organization


</span>
            </div>
        </div>
        <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 1rem;">
            <GlobalTabs :notIdx="true" :tabs="tabs" />
            <AddButtonGlobal @button="handleNewNudge" />
        </div>
        <div class="playbook__body">
            <GlobalTable style="height: 69vh;" v-if="!loading && tableData.length>0" :columns="columns" :showAllTooltips="true" :tableData="tableData" @handleAction="handleAction($event)"/>
            <div v-else-if="!loading && tableData.length==0" style="display: flex; align-items: center; justify-content: center; height: 69vh;">
                <img src="../icons/noData.svg" style="height: 12vw;" alt="">
            </div>
            <div v-else style="display: flex; justify-content: center; align-items: center; height: 69vh; overflow: scroll;">
                <img src="../assets/loadingRipple.svg" style="height: 150px;" alt="">
            </div>
            <TemplateConfiguration @close="openNudge = false;initData()" :isEditing="editingStatus" :promptdata="promptData" v-if="openNudge" />
            <!-- <PlayBookCustom v-if="selectedTab==0" /> -->
            <!-- <SystemNudge v-if="selectedTab==1" /> -->

        </div>
        <div class="" v-if="openNudge">
            <!-- <NudgesConfiguration @save="handleSaveNudge($event)" :isEditingIdx="-1" @close="openNudge=false" :nudge="nudgeData" /> -->
        </div>
    </div>
</template>

<script>
import NudgesConfiguration from '../components/NudgesConfiguration.vue';
import PlayBookCustom from '../components/PlayBookCustom.vue';
import Server from '../components/API/Server';
import SystemNudge from '../components/SystemNudge.vue';
import LocalUtils from '../components/Utils/LocalUtils';
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import TemplateConfiguration from "./TemplateConfiguration"
import GlobalTabs from '../layout/dashboard/GlobalTabs.vue';
import GlobalTable from '../layout/dashboard/GlobalTable.vue';
import moment from 'moment';
export default {
    components: {
        PlayBookCustom,
        NudgesConfiguration,
        AddButtonGlobal,
        SystemNudge,
        GlobalTabs,
        GlobalTable,
        TemplateConfiguration
    },
    data() {
        return {
            tabs: ["Templates"],
            selectedTab: 0,
            loading: false,
            columns: [
                {
                    title: "Name",
                    key: "name",
                    width: "30%"
                },
                
                {
                    title: "Type",
                    key: "promtType",
                    width: "15%"
                },
               
                {
                    title: "Created At",
                    key: "created_date",
                    width: "15%"
                },
                {
                    title: "Modified At",
                    key: "updated_date",
                    width: "15%"
                },
                {
                    title: "Modified By",
                    key: "email",
                    width: "15%"
                },
                {
                    title: "Action",
                    key: "action",
                    width: "10%"
                }
            ],
            promptData: {},
            tableData: [],
            nudgeData: {},
            editingStatus: false,
            openNudge: false,
        }
    },
    methods: {
        handleNewNudge() {
            this.openNudge = true
        },
        handleAction(action){
            switch (action[0]) {
                case 'openEdit':
                    this.handleOpenEdit(action[1]);
                    break;
                case 'openDelete':
                    this.handleDelete(action[1], action[2]);
                    break;
            
                default:
                    break;
            }
        },
        handleOpenEdit(data) {
            console.log(data);
            this.editingStatus = true;
            this.promptData = data;
            this.openNudge = true;
        },
        async handleDelete(data, idx){
            console.log(data);
            this.tableData.splice(idx, 1);
            let resp = await Server.deleteEmailTemplate(data.promptId);
            console.log(resp);
        },
        async initData(){
            this.loading = true;
            this.tableData=[]
            let response = await Server.getprompts();
        console.log(response);
        if (response.data) {
            console.log('working', response.data)
            for (let i = 0; i < response.data.length; i++) {
                console.log('rege')
                response.data[i]['created_date']=moment(response.data[i]['created_date']).format("DD MMM,YY")
                response.data[i]['updated_date']=moment(response.data[i]['updated_date']).format("DD MMM,YY hh:mm")

                this.tableData.push(response.data[i]);

            }
        }
        console.log(this.tableData)
        for(let i=0;i<this.tableData.length;i++){
            this.tableData[i]['action'] = [{'img':'Edit.png', 'action': 'openEdit', 'hover': 'Edit'}, {'img':'Delete.png', 'action': 'openDelete', 'hover': 'Delete'}];
        }
        this.loading = false;
        }
    },
    async mounted() {
       this.initData()
    }

}
</script>
<style lang="scss" scoped>
.playbook {
    padding: 14px;

    &__body {
        width: 100%;
        margin-right: 30px;
        height: 100%;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-top: 1vw;
        margin-left: 1.25vw;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }

    &__tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgb(228, 224, 224);
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 25px;
        width: 85%;

        &__tab {
            cursor: pointer;
            padding: 8px;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.selecetdTab {
    font-weight: 600;

    border-bottom: 2px solid blue;
}</style>